import './App.css';

import { Login } from './Auth/Login';
import { Dashboard } from './Modules/Dashboard';

const AUTH = sessionStorage.getItem("auth");

function App() {

  document.title = '☀'

  if (AUTH) 
    return <Dashboard/>

  return <Login/>

}

export default App;
