import React from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MainNote } from "./MainNote/MainNote";
import { AddressBook } from "./AddressBook/AddressBook";
import { Handle } from "../Service/Handle";


export const Dashboard = (props) => {

    let module = sessionStorage.getItem("module");    
    
    if(module=="MainNote")
        return <MainNote/>
    
    if(module=="AddressBook")
        return <AddressBook/>


    return (
      <Container fluid>
        <Row className="align-items-center" style={{height: 100+"vh"}}>
          <Col className="d-flex justify-content-center">
            <Button onClick={() => Handle.ClickModule("MainNote")}>
                Hlavní poznámka
            </Button>
            <Button onClick={() => Handle.ClickModule("AddressBook")}>
                Adresy
            </Button>

            <Button onClick={Handle.ClickLogout}>
                Logout
            </Button>
          </Col>
        </Row>
      </Container>
    )
}