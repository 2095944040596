import React, { useState } from "react";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import sha256 from 'js-sha256';


export const Login = (props) => {
    const [password, setPass] = useState('');
    const KEY = "3e7c3d4cf5be91eca62500b41e292c3474ecc0329156d11f682b609c42828df7"
    
    //console.log(sha256('*****'));

    const handleSubmit = (e) => {
        e.preventDefault();
        if(KEY==sha256(password)) {
          sessionStorage.setItem("auth", true);
          window.location.reload();
        } else
          sessionStorage.setItem("auth", false);
    }

    return (
      <Container fluid>
        <Row className="align-items-center" style={{height: 100+"vh"}}>
          <Col className="d-flex justify-content-center">
              <Form onSubmit={handleSubmit}>    
                <Form.Control value={password} onChange={(e) => setPass(e.target.value)} type="password" placeholder="Heslo" name="password"/>
                <Button variant="primary" type="submit" style={{width:"100%"}}>
                  Přihlásit se
                </Button>
              </Form>
          </Col>
        </Row>
      </Container>
    )
}