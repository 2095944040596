
export let Handle = {
    
    ClickModule: (name) => {
        sessionStorage.setItem("module", name);    
        window.location.reload();
    },

    ClickLogout: () => {
        sessionStorage.removeItem("auth");    
        sessionStorage.clear();    
        window.location.reload();
    },
    
    ClickBackToModule: () => {
        sessionStorage.removeItem("module");    
        window.location.reload();
    }
      
}