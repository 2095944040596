import React from "react";
import {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Handle } from '../../Service/Handle.js'
import { JsonEditor } from '../../Service/JsonEditor.js'
import axios from 'axios';


export const AddressBook = (props) => {

    const path = 'data/address.json';

    const [json, setJson] = useState({k:1});
      
    let init = async() => {
      setJson(await JsonEditor.Load(path));
    }

    useEffect(()=> {
      init();
      console.log(json);
    },[])

    let TextControls =(obj) => {
      return (
          <Form.Group className={obj.class} controlId={obj.id} key={obj.key}>
            <Form.Label>{obj.label}</Form.Label>
            <Form.Control type="text" onChange={obj.onChange} value={obj.value}/>
          </Form.Group>
      );
    }
    
    axios.defaults.withCredentials = true;
    const handleOnChange = (k, v) => {
      //setJson({...json, [k]:v});
      let j = Object.assign({}, json);
      j[k] = v;
      setJson(j)
      save(j);
    }
    
    const save =  (json) => {
      console.log(json);
      //return;
      axios.post("save.php", 
          {
              path: path,
              text: JSON.stringify(json)
          },
          {
              headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Credentials': true,
                  'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
              }
          })
          .then(response => {      
              console.log(response);
          })
          .catch(err => {
              console.error(err);
          });
  };


    return (
      <Container fluid>
        <Button onClick={Handle.ClickBackToModule}>
            ←
        </Button>
        {
          Object.keys(json).map((k, i) => {
            return TextControls({
              key: k,
              id: "control-"+k,
              class: "mb-3",
              label: k,
              value: json[k],
              onChange: (e) => { 
                e.preventDefault();
                handleOnChange(k, e.target.value)
              }
            })
          })
        }
      </Container>
    )
}