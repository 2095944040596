import React from "react";
import {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { Handle } from '../../Service/Handle.js'

//const localhostPHP = "http://localhost:3000/doc-rzweb-cz/public/";
const localhostPHP ="";

export const MainNote = (props) => {
    
    const [text, setText] = useState("");

    const loadData = async () => {
        let resp = await axios.get('main_note.txt');
        if(resp.status==200)
            setText(resp.data)
    }
    useEffect(()=> {
        loadData();
    },[])

    axios.defaults.withCredentials = true;
    const handleMessageChange = e => {
        setText(e.target.value);
        e.preventDefault();
        axios.post(localhostPHP+"save.php", 
            {
                path: "main_note.txt",
                text: e.target.value
            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Credentials': true,
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
            .then(response => {      
                console.log(response);
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
      <Container fluid>
        <Button onClick={Handle.ClickBackToModule}>
            ←
        </Button>

        <Form>
            <Form.Control as="textarea" rows={12} onChange={handleMessageChange} value={text} />
        </Form>
      </Container>
    )
}